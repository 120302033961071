



import { Component, Prop, Vue } from "vue-property-decorator";
import LazyImage from "./LazyImage.vue";

@Component
export default class NiceContent extends Vue {
  @Prop() htmlContent!: string;

  get html(): string {
    // hacky way to prevent browser from actually loading the image's source on mount
    // https://css-tricks.com/lazy-loading-images-with-vue-js-directives-and-intersection-observer/#aa-step-2-prevent-the-image-from-being-loaded-when-the-component-is-created
    return this.htmlContent.replaceAll("<img src=", "<img data-url=");
  }

  mounted() {
    // ref: https://levelup.gitconnected.com/vue-js-replace-a-with-router-link-in-dynamic-html-c423beea0d17
    Array.from(this.$el.getElementsByTagName("img")).forEach((img) => {
      const url = img.dataset["url"];
      if (url) {
        const info = undefined; // TODO: load contentful image info here
        const lazyImg = new LazyImage({
          propsData: info ? info : { url },
          parent: this,
        });
        lazyImg.$mount(img);
      }
    });
  }
}
