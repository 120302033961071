import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "@/views/Home.vue";
import Post from "@/views/Post.vue";
import Blog from "@/views/Blog.vue";
import Projects from "@/views/5000.vue";
import { posts } from "@/data/posts";
Vue.use(VueRouter);

const postRoutes = posts.map((p): RouteConfig => {
  // these include both posts and pages
  return {
    path: p.slug, // includes the leading slash
    component: Post,
    props: { post: p },
  };
});

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/blog",
    name: "Blog",
    component: Blog,
  },
  {
    path: "/projects",
    name: "projects",
    component: Projects,
  },
  {
    path: "/blog/p/:pageNumber",
    name: "Blog Page",
    component: Blog,
  },
  ...postRoutes,
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  // ref: https://router.vuejs.org/guide/advanced/scroll-behavior.html
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash,
      };
    }
    if (savedPosition) {
      // back button
      return savedPosition;
    }
    // scroll to top on new page
    return { x: 0, y: 0 };
  },
});

export default router;
