import contentfulContent from "./data.json";
import { getField, assetFiles, getLinks, english } from "./contentful";
import { departments } from "./departments";
import { md } from "./markdown";

export interface Post {
  slug: string;
  title: string;
  date: Date;
  contentHTML: string;
  coverImageURL?: string;
  shortDescription: string;
  departmentIDs: string[];
  tags: string[];
}

const entries = contentfulContent.entries.filter(
  (e) => e.sys.type === "Entry" && e.sys.contentType.sys.id === "post"
);

export const posts = entries
  .map((e): Post => {
    const imageCover = assetFiles.find((f) => {
      const links = getLinks(e, "coverImage");
      if (links.length === 0) return false;
      return f.sysid === links[0].id;
    });
    return {
      slug: "/" + getField(e, "slug"),
      title: getField(e, "title"),
      date: new Date(
        Date.parse(getField(e, "publishDate") || "2018-01-01T00:00-07:00")
      ),
      contentHTML: md.render(getField(e, "mainContent")),
      coverImageURL: imageCover ? imageCover.url : undefined,
      shortDescription: getField(e, "shortDescription"),
      departmentIDs: getLinks(e, "departments").map((link) => {
        const dp = departments.find((dp) => link.id === dp.sysID);
        return dp ? dp.devID : "";
      }),
      tags: e.fields.tags ? e.fields.tags[english] : [],
    };
  })
  .sort((a, b) => {
    // most recent first
    return b.date.getTime() - a.date.getTime();
  });
