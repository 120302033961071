import { getField } from "./contentful";
import contentfulContent from "./data.json";

export interface Department {
  slug: string;
  title: string;
  devID: string;
  sysID: string;
}

const entries = contentfulContent.entries.filter(
  (e) => e.sys.type === "Entry" && e.sys.contentType.sys.id === "department"
);

export const departments = entries.map((e): Department => {
  return {
    slug: getField(e, "slug"),
    title: getField(e, "title"),
    devID: getField(e, "devId"),
    sysID: e.sys.id,
  };
});
