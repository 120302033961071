



























import { Component, Vue, Prop } from "vue-property-decorator";
import { Post } from "@/data/posts";
import NiceContent from "@/components/NiceContent.vue";
import strftime from "strftime";

@Component({ components: { NiceContent } })
export default class PostView extends Vue {
  // this template currently renders both posts and pages
  // pages are just posts with department != {5000, blog}
  @Prop() post!: Post;

  get department(): string {
    if (this.post.departmentIDs.includes("5000")) return "projects";
    else if (this.post.departmentIDs.includes("blog")) return "blog";
    return "";
  }
  get departmentName(): string {
    if (this.department === "projects") return "Projects";
    else if (this.post.departmentIDs.includes("blog")) return "Blog";
    return "";
  }
  get departmentNameBackTo(): string {
    if (this.department === "projects") return this.departmentName;
    else if (this.department === "blog") return "posts";
    else return "";
  }

  formatDate(d: Date): string {
    return strftime("%D", d);
  }
}
