import contentfulContent from "./data.json";

export const english = "en-US";
export interface ContentfulLink {
  id: string;
  linkType: "Entry" | "Asset";
}

export interface Asset {
  sysid: string;
  url: string;
  fileName: string;
  contentType: string;
}

export function getField(e: any, name: string): string {
  return (e.fields[name] || { english: "" })[english] || "";
}

export function getLinks(e: any, name: string): ContentfulLink[] {
  if (!e.fields[name]) return [];
  return e.fields[name][english].map((f: any) => {
    return { id: f.sys.id, linkType: f.sys.linkType };
  });
}

export const assetFiles: Asset[] = contentfulContent.assets
  .filter((e) => e.fields.file !== undefined)
  .map((e) => {
    const file = e.fields.file![english];
    return {
      sysid: e.sys.id,
      url: file.url || "",
      fileName: file.fileName,
      contentType: file.contentType,
    };
  });

export const entries = contentfulContent.entries;
