
























import { Component, Vue } from "vue-property-decorator";
import LazyImage from "@/components/LazyImage.vue";
import { posts, Post } from "@/data/posts";
import strftime from "strftime";
@Component({ components: { LazyImage } })
export default class Blog extends Vue {
  itemsPerPage = 12;
  private get blogPostsAll(): Post[] {
    return posts.filter((p) => p.departmentIDs.includes("blog"));
  }

  get numberBlogPosts(): number {
    return this.blogPostsAll.length;
  }

  get blogPosts(): Post[] {
    const startIndex = this.itemsPerPage * (this.currentPage - 1);
    const endIndex = startIndex + this.itemsPerPage;
    return this.blogPostsAll.slice(startIndex, endIndex);
  }

  get currentPage(): number {
    try {
      return parseInt(this.$route.params.pageNumber || "1");
    } catch {
      return 1;
    }
  }

  formatDate(d: Date): string {
    return strftime("%D", d);
  }

  changePage(p: number) {
    this.$router.push(`/blog/p/${p}`);
  }
}
