import "vue-class-component/hooks"; // import hooks type to enable auto-complete
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import Buefy from "buefy";
// @ts-ignore
import vueHeadful from "vue-headful";
// @ts-ignore
import Pagination from "vue-2-bulma-pagination";
import "./assets/scss/app.scss";

Vue.use(Buefy);
Vue.component("vue-headful", vueHeadful);
Vue.component("pagination", Pagination);
Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
