







import { Component, Vue } from "vue-property-decorator";
import SiteNavbar from "@/components/Navbar.vue";
import SiteFooter from "@/components/Footer.vue";

@Component({ components: { SiteNavbar, SiteFooter } })
export default class App extends Vue {}
