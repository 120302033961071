















import { Component, Vue } from "vue-property-decorator";
import { posts } from "@/data/posts";
import strftime from "strftime";
@Component({})
export default class Projects extends Vue {
  posts = posts
    .filter((p) => p.departmentIDs.includes("5000"))
    .sort((a, b) => b.date.getTime() - a.date.getTime());

  formatDate(d: Date): string {
    return strftime("%D", d);
  }
}
